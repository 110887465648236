import React, { useEffect, useState } from "react"
import { Link, navigate } from "gatsby"
import axios from "axios"
import SEO from "../components/seo"
import Login from "../components/login"
import { Helmet } from "react-helmet"
import Footer from "../components/footer"


const GatePage = () => {
  //   const [taps, setTaps] = useState(0)
  const [currentWord, setCurrentWord] = useState("")

  useEffect(() => {


    const checkYoself = async () => {

      const loggedInUser = window.localStorage.getItem("loggedInUser")
      // if (!loggedInUser) {window.location.replace("https://www.google.com/")}

      if (loggedInUser) {
        const loggedInUserJSON = JSON.parse(loggedInUser)
        const isValidUser = await axios.post('https://api.sup.cool/users/auth', { token: loggedInUserJSON.token })

        if (isValidUser.request.status === 200) {
          navigate('/notebook_5183')
        }
      }
    }

    checkYoself()

  }, [])


  return (
    <div style={{ overflow: 'hidden' }}>

      <Helmet>
        <script
          src="https://kit.fontawesome.com/e4bfb220a1.js"
          crossorigin="anonymous"
        ></script>

      </Helmet>


      {/* <i className="fas fa-home" style={{ zIndex: '20', color: 'rgba(60, 70, 92, .75)', fontSize: '16px', position: 'absolute', right: '21px', top: '28px', opacity: '.5' }} onClick={() => { navigate('/') }}></i> */}


      {/* <div className="migraine" style={{
        position: 'absolute',
        display: 'block',
        width: '100%',
        height: '100%',
      }}
      > */}

      {/* <p className="breathe" style={{position: 'absolute', textAlign:'center', left:'50%', transform: 'translateX(-50%)', top: '26%', fontSize: '14px', color: 'rgba(41,41,41,.75)'}}>leave me alone</p> */}

      <div style={{ width: '100%', height: '100%', position: 'absolute' }}>
        <SEO title="treefort" />
        <Login />
      </div>

      {/* <Footer></Footer> */}

      {/* </div> */}
    </div>
  )
}

export default GatePage